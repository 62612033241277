/* eslint-disable react/no-children-prop */
import React from "react"
import Markdown from "react-markdown"
import styled from "styled-components/macro"
import { useStaticQuery, graphql } from "gatsby"
import { LinkRenderer, HeadingRenderer } from "../../utils/markdownUtils"

import { QUERY, COLORS } from "../../constants"

const CallMe = () => {
  const data = useStaticQuery(graphql`
    query CallMeQuery {
      strapiCallMe {
        Pulsante {
          icon {
            file {
              publicURL
            }
            alternativeText
          }
          label
          target
          href
        }
        testo
      }
    }
  `)

  const { testo, Pulsante } = data.strapiCallMe
  const { href, label, target, icon } = Pulsante

  return (
    <Background>
      <Wrapper>
        <Paragrafo
          renderers={{
            heading: HeadingRenderer,
            link: LinkRenderer,
          }}
          children={testo}
        />
        <PhoneWrapper href={href} target={target}>
          {icon && <Icon src={icon.file.publicURL} alt="" />}
          {label && <span>{label}</span>}
        </PhoneWrapper>
      </Wrapper>
    </Background>
  )
}

const Background = styled.section`
  background: ${COLORS.lightBlue};
  padding: 2rem 0 12rem 0;

  @media ${QUERY.smaller} {
    padding-bottom: 8rem;
  }
`

const Wrapper = styled.div`
  width: 80%;
  display: flex;
  align-items: flex-start;
  gap: 4rem;
  padding: 2rem 2rem 2rem 5rem;
  margin-left: auto;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background: ${COLORS.white};

  @media ${QUERY.medium} {
    gap: 2rem;
    padding: 2rem 2rem 2rem 3rem;
  }

  @media ${QUERY.small} {
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 2rem 2rem 3rem;
  }

  @media ${QUERY.verySmall} {
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 1rem 2rem 2rem;
  }
`

const Paragrafo = styled(Markdown)`
  grid-area: testo;
  text-transform: uppercase;
  width: 80%;
  font-size: calc(0.9 * var(--baseline-size));
  @media ${QUERY.medium} {
    font-size: calc(0.8 * var(--baseline-size));
  }
`

const PhoneWrapper = styled.a`
  width: 250px;
  padding: 0.5rem 0;
  color: ${COLORS.black};
  margin: 0 1rem 0 0;
  border-radius: 10px;
  background: ${COLORS.white};
  display: flex;
  align-items: center;
  text-decoration: none;
  -webkit-box-shadow: inset 5px 5px 10px 0px #ffffff, 5px 5px 10px 0px #cccccc;
  -moz-box-shadow: inset 5px 5px 10px 0px #ffffff, 5px 5px 10px 0px #cccccc;
  -o-box-shadow: inset 5px 5px 10px 0px #ffffff, 5px 5px 10px 0px #cccccc;
  box-shadow: inset 5px 5px 10px 0px #ffffff, 5px 5px 10px 0px #cccccc;

  span {
    margin-left: 0.5rem;
    color: inherit;
    font-weight: 500;
    font-size: calc(1.3 * var(--baseline-size));
  }

  @media ${QUERY.small} {
    width: 200px;
    margin: 0;
    padding: 0.5rem 1.5rem 0.5rem 0;
    span {
      font-weight: 400;
      font-size: calc(1.1 * var(--baseline-size));
      padding-left: 0.2rem;
    }
  }

  @media ${QUERY.verySmall} {
    width: 160px;
    span {
      font-size: calc(1 * var(--baseline-size));
    }
  }
`
const Icon = styled.img`
  width: 40px;
  margin-left: 1rem;

  @media ${QUERY.medium} {
    width: 30px;
    margin-left: 1rem;
  }

  @media ${QUERY.verySmall} {
    width: 25px;
    margin-left: 0.5rem;
  }
`

export default CallMe
