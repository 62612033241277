/* eslint-disable react/no-children-prop */
import React from "react"
import Markdown from "react-markdown"
import { createMedia } from "@artsy/fresnel"
import styled from "styled-components/macro"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { LinkRenderer, HeadingRenderer } from "../../utils/markdownUtils"
import { QUERY, COLORS } from "../../constants"

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    ml: 1280,
  },
})

const Associazione = () => {
  const data = useStaticQuery(graphql`
    query AssociazioneQuery {
      strapiAssociazione {
        testo
        immagine {
          file {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        filoRosso {
          file {
            publicURL
          }
        }
        filoRossoMobile {
          file {
            publicURL
          }
        }
      }
    }
  `)

  const { testo, immagine, filoRosso, filoRossoMobile } =
    data.strapiAssociazione

  return (
    <MediaContextProvider>
      <Media at="sm">
        <Wrapper bgImg={filoRossoMobile.file.publicURL}>
          <Bg />
          <Paragrafo
            renderers={{
              heading: HeadingRenderer,
              link: LinkRenderer,
            }}
            children={testo}
          />
          <GatsbyImageWrapper
            image={immagine.file.childImageSharp.gatsbyImageData}
            alt=""
          />
        </Wrapper>
      </Media>
      <Media at="ml">
        <Wrapper bgImg={filoRosso.file.publicURL}>
          <Bg />
          <Paragrafo
            renderers={{
              heading: HeadingRenderer,
              link: LinkRenderer,
            }}
            children={testo}
          />
          <GatsbyImageWrapper
            image={immagine.file.childImageSharp.gatsbyImageData}
            alt=""
          />
        </Wrapper>
      </Media>
    </MediaContextProvider>
  )
}

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  padding: 12rem;
  background: ${props => `url(${props.bgImg})`} no-repeat 0% 50%;
  background-size: cover;

  @media ${QUERY.large} {
    padding: 12rem 8rem;
  }

  @media ${QUERY.mediumLarge} {
    flex-direction: column-reverse;
    padding: 10rem 3rem;
    gap: 2rem;
    background-size: contain;
    background-position: 0% 0%;

    & > * + * {
      margin-bottom: 2rem;
    }
  }

  @media ${QUERY.medium} {
    padding: 8rem 1rem;
  }

  @media ${QUERY.small} {
    background-position: 0% 6%;
  }

  @media ${QUERY.smallest} {
    padding: 6rem 1rem;
  }
`

const Bg = styled.div`
  background: ${COLORS.lightBlue};
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 200px;
`

const GatsbyImageWrapper = styled(GatsbyImage)`
  object-fit: cover;
  border-radius: 200px;
  img {
    border-radius: 200px;
  }

  @media ${QUERY.larger} {
    max-height: 400px;
    min-width: 640px;
  }

  @media ${QUERY.mediumLarge} {
    max-width: 900px;
    min-width: 280px;
    width: 90%;
  }
`

const Paragrafo = styled(Markdown)`
  min-width: 50%;
  max-width: 50%;
  padding-top: 1.5rem;
  padding-right: 4rem;
  padding-left: 1rem;
  font-size: calc(1 * var(--baseline-size));
  p {
    margin-bottom: 2rem;
  }

  @media ${QUERY.mediumLarge} {
    max-width: 900px;
    min-width: 280px;
    width: 80%;
    padding-right: 0;
    padding-left: 0;
  }

  @media ${QUERY.mediumLarge} {
    width: 90%;
  }
`

export default Associazione
