import React from "react"

import Hero from "../Hero"
import Maestro from "../Maestro"
import Footer from "../Footer"
import Corsi from "../Corsi"
import Taichichuan from "../Taichichuan"
import Associazione from "../Associazione"
import CallMe from "../CallMe"

const App = () => {
  return (
    <>
      <Hero />
      <Corsi />
      <CallMe />
      <Associazione />
      <Maestro />
      <Taichichuan />
      <Footer />
    </>
  )
}

export default App
