/* eslint-disable react/no-children-prop */
import React from "react"
import Markdown from "react-markdown"
import styled from "styled-components/macro"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { LinkRenderer, HeadingRenderer } from "../../utils/markdownUtils"

import MySwiper from "../MySwiper"
import { QUERY, COLORS } from "../../constants"

const Maestro = () => {
  const data = useStaticQuery(graphql`
    query MaestroQuery {
      strapiMaestro {
        titolo
        bgImage {
          file {
            publicURL
          }
        }
        carousel {
          ... on StrapiComponentSharedEdito {
            id
            testo
            titolo
          }
          ... on StrapiComponentSharedMedia {
            id
            mediaFile {
              file {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `)

  const { titolo, carousel, bgImage } = data.strapiMaestro
  const slides = carousel.map(item => (
    <Slide key={item.id}>
      {item.testo && (
        <div>
          <Titolo>{item.titolo}</Titolo>
          <Testo
            renderers={{
              heading: HeadingRenderer,
              link: LinkRenderer,
            }}
            children={item.testo}
          />
        </div>
      )}
      {item.mediaFile && (
        <Image
          image={item.mediaFile.file.childImageSharp.gatsbyImageData}
          alt=""
        />
      )}
    </Slide>
  ))

  return (
    <Carousel bgImg={bgImage.file.publicURL}>
      <MainTitle>{titolo}</MainTitle>
      <MySwiper slides={slides} />
    </Carousel>
  )
}

const Slide = styled.div`
  border: 1px solid ${COLORS.blue};
  border-radius: 30px;
  background-color: ${COLORS.white};
  min-width: 300px;
  padding: 1rem;
  margin-top: 2rem;

  @media ${QUERY.small} {
    min-width: 400px;
  }

  @media ${QUERY.smaller} {
    min-width: 250px;
  }
`

const MainTitle = styled.h1`
  background-color: ${COLORS.white};
  color: ${COLORS.black};
  margin-top: 10rem;
  padding: 8rem 4rem 0 5rem;
  font-size: calc(2 * var(--baseline-size));
  font-weight: 100;

  @media ${QUERY.large} {
    margin-top: 0;
    padding-top: 5rem;
  }

  @media ${QUERY.medium} {
    text-align: center;
  }

  @media ${QUERY.small} {
    font-size: calc(1.2 * var(--baseline-size));
    padding: 1rem;
  }
`

const Carousel = styled.section`
  background: ${COLORS.lightBlue};
  @media ${QUERY.medium} {
    background: ${props => `url(${props.bgImg})`} ${COLORS.lightBlue} no-repeat
      0% 100%;
    background-size: contain;
  }
  @media ${QUERY.small} {
    font-size: calc(1-2 * var(--baseline-size));
    padding: 0;
  }
`

const Image = styled(GatsbyImage)`
  object-fit: cover;
  width: 100%;
  height: auto;
  border-radius: 20px;
  img {
    border-radius: 20px;
  }
`

const Testo = styled(Markdown)`
  padding: 1rem;
  font-size: calc(1 * var(--baseline-size));
  font-weight: 300;
  @media ${QUERY.mediumSmall} {
    font-size: calc(0.9 * var(--baseline-size));
    padding: 1rem 0.5rem;
  }
`

const Titolo = styled.h3`
  padding: 1rem;
  font-size: calc(1.2 * var(--baseline-size));
  font-weight: 600;
  background-color: ${COLORS.red};
  color: ${COLORS.white};
  border-radius: 20px;
`

export default Maestro
