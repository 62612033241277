/* eslint-disable react/no-children-prop */
import React from "react"
import Markdown from "react-markdown"
import styled from "styled-components/macro"
import { useStaticQuery, graphql } from "gatsby"
import { LinkRenderer, HeadingRenderer } from "../../utils/markdownUtils"

import CorsoCard from "../CorsoCard"

import { QUERY, COLORS } from "../../constants"

const Corsi = () => {
  const data = useStaticQuery(graphql`
    query CorsiQuery {
      strapiCorsi {
        testo
        listaCorsi {
          ... on StrapiComponentGlobalNavigationPush {
            id
            indirizzo
            location
            orari
            googleMapsLink {
              href
              icon {
                file {
                  publicURL
                }
              }
              label
              target
            }
            image {
              file {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `)

  const { testo, listaCorsi } = data.strapiCorsi

  return (
    <Wrapper>
      <Paragrafo
        renderers={{
          heading: HeadingRenderer,
          link: LinkRenderer,
        }}
        children={testo}
      />
      <ListaCorsi>
        {listaCorsi.map(
          ({ id, location, orari, indirizzo, googleMapsLink, image }) => (
            <CorsoCard
              key={id}
              location={location}
              indirizzo={indirizzo}
              orari={orari}
              googleMapsLink={googleMapsLink}
              image={image}
            />
          )
        )}
      </ListaCorsi>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  display: grid;
  min-width: 100%;
  grid-template-columns: 1fr 2fr;
  grid-template-areas: "testo cards";
  margin: 0 auto;
  padding: 10rem 4rem 6rem 10rem;
  background: ${COLORS.lightBlue};
  @media ${QUERY.mediumLarge} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${QUERY.mediumSmall} {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6rem;
  }

  @media ${QUERY.small} {
    padding: 4rem 2rem;
  }
`

const ListaCorsi = styled.div`
  grid-area: cards;
  display: flex;
  flex-wrap: wrap;

  @media ${QUERY.mediumSmall} {
    margin-top: 2rem;
    flex-direction: column;
    align-items: center;
  }
`

const Paragrafo = styled(Markdown)`
  grid-area: testo;
  width: 80%;
  font-size: calc(1 * var(--baseline-size));
  p {
    margin-bottom: 2rem;
  }

  @media ${QUERY.small} {
    width: 100%;
  }
`

export default Corsi
