import * as React from "react"
import { Helmet } from "react-helmet"

import App from "../components/App"
import FontStyles from "../components/FontStyles"
import GlobalStyles from "../components/GlobalStyles"

const IndexPage = () => {
  return (
    <>
      <FontStyles />
      <GlobalStyles />
      <Helmet htmlAttributes={{ lang: "it" }}>
        <title>ASD Yuan - Scuola di Tai Chi Chuan</title>
        <meta
          name="description"
          content="Scuola di Tai Chi Chuan del Maestro Jiang Yi. CORSI A Gallarate, Busto Arsizio, Varese, Vedano Olona, Daverio e Venegono Superiore."
        />
        <link rel="canonical" href="https://www.taichiyuan.it/" />
      </Helmet>
      <App />
    </>
  )
}

export default IndexPage
