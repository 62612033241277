/* eslint-disable react/no-children-prop */
import React from "react"
import Markdown from "react-markdown"
import { GatsbyImage } from "gatsby-plugin-image"
import { LinkRenderer, HeadingRenderer } from "../../utils/markdownUtils"

import styled from "styled-components/macro"

import { QUERY, COLORS } from "../../constants"

import IconPush from "../IconPush"

const CorsoCard = ({ location, orari, indirizzo, googleMapsLink, image }) => {
  const { label, href, target, icon } = googleMapsLink
  return (
    <Wrapper>
      <Location>{location}</Location>
      <FlexWrapper>
        <GatsbyImageWrapper
          image={image.file.childImageSharp.gatsbyImageData}
          alt=""
        />
        <Orari
          renderers={{
            heading: HeadingRenderer,
            link: LinkRenderer,
          }}
          children={orari}
        />
      </FlexWrapper>
      <Indirizzo
        renderers={{
          heading: HeadingRenderer,
          link: LinkRenderer,
        }}
        children={indirizzo}
      />
      <IconPush label={label} href={href} target={target} icon={icon} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  max-width: 320px;
  width: 100%;
  margin: 0 2rem 2rem 0;
  padding: 1rem;
  position: relative;
  border-radius: 30px;
  border: 1px solid ${COLORS.blue};
  background: ${COLORS.white};

  @media ${QUERY.mediumLarge} {
    max-width: 400px;
  }

  @media ${QUERY.mediumSmall} {
    max-width: 500px;
    margin: 1rem auto;
  }
`

const GatsbyImageWrapper = styled(GatsbyImage)`
  object-fit: cover;
  height: 100px;
  border-radius: 15px;
  img {
    border-radius: 15px;
   }
`

const FlexWrapper = styled.div`
  display: flex;
`

const Location = styled.span`
  text-transform: uppercase;
  font-size: calc(1.6 * var(--baseline-size));
  font-weight: 100;
  display: block;
  margin-bottom: 0.5rem;

  @media ${QUERY.smaller} {
    font-size: calc(1.2 * var(--baseline-size));
  }
`

const Orari = styled(Markdown)`
  min-width: 120px;
  padding-left: 1rem;
  text-transform: uppercase;
  font-size: calc(1 * var(--baseline-size));
  font-weight: 400;
`

const Indirizzo = styled(Markdown)`
  text-transform: lowercase;
  font-size: calc(0.9 * var(--baseline-size));
  font-weight: 400;
  padding: 0.5rem 0.2rem;
  width: 80%;

  p {
    margin: 0.5rem 0;
  }
`

export default CorsoCard
