/* eslint-disable react/no-children-prop */
import React from "react"
import Markdown from "react-markdown"
import { useStaticQuery, graphql } from "gatsby"
import { LinkRenderer, HeadingRenderer } from "../../utils/markdownUtils"
import styled from "styled-components/macro"
import { QUERY, COLORS } from "../../constants"
import Video from "../Video"

const Taichichuan = () => {
  const data = useStaticQuery(graphql`
    query TaichichuanQuery {
      strapiTaichichuan {
        citazione
        filoRosso {
          file {
            publicURL
          }
        }
        video {
          file {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        taichiInfo {
          ... on StrapiComponentSharedEdito {
            id
            testo
            titolo
          }
        }
      }
    }
  `)

  const { citazione, taichiInfo, video, filoRosso } = data.strapiTaichichuan
  const testi = taichiInfo.filter(item => item.testo && item)

  console.log(testi)

  return (
    <Wrapper>
      <VideoWrapper>
        <Video url={video.file.publicURL} />
      </VideoWrapper>
      <FiloRosso src={filoRosso.file.publicURL} alt="" />
      <Citazione>{citazione}</Citazione>
      <ParaOne>
        <Titolo>{testi[0].titolo}</Titolo>
        <Testo
          renderers={{
            heading: HeadingRenderer,
            link: LinkRenderer,
          }}
          children={testi[0].testo}
        />
      </ParaOne>
      <ParaTwo>
        <Titolo>{testi[1].titolo}</Titolo>
        <Testo
          renderers={{
            heading: HeadingRenderer,
            link: LinkRenderer,
          }}
          children={testi[1].testo}
        />
      </ParaTwo>
      <ParaThree>
        <Titolo>{testi[2].titolo}</Titolo>
        <Testo
          renderers={{
            heading: HeadingRenderer,
            link: LinkRenderer,
          }}
          children={testi[2].testo}
        />
      </ParaThree>
      <Bg />
    </Wrapper>
  )
}

const Wrapper = styled.section`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 200px 1fr;
  grid-template-rows: repeat(4, min-content);
  padding: 0 0 10rem 5rem;

  @media ${QUERY.larger} {
    padding: 10rem 0 8rem 3rem;
  }

  @media ${QUERY.medium} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, min-content);
    padding: 6rem 10rem;
  }

  @media ${QUERY.small} {
    padding: 6rem 4rem;
  }

  @media ${QUERY.smaller} {
    padding: 0 2rem 6rem 2rem;
  }

  @media ${QUERY.verySmall} {
    padding: 0 1rem 6rem 1rem;
  }
`

const FiloRosso = styled.img`
  grid-row: 1/-1;
  grid-column: 3/-1;
  z-index: -1;
  object-fit: cover;
  width: 100%;

  @media ${QUERY.medium} {
    display: none;
  }
`

const VideoWrapper = styled.div`
  grid-row: 2/3;
  grid-column: 1/3;
  max-width: 90%;

  @media ${QUERY.medium} {
    grid-row: 3/4;
    grid-column: 1/-1;
  }

  @media ${QUERY.smaller} {
    grid-row: 3/4;
    grid-column: 1/-1;
    max-width: 100%;
  }
`

const Citazione = styled.div`
  grid-row: 2/3;
  grid-column: 4/-1;
  margin-right: 2rem;
  font-family: var(--font-serif);
  font-size: calc(1.2 * var(--baseline-size));

  @media ${QUERY.custom} {
    margin-top: -10rem;
  }

  @media ${QUERY.medium} {
    grid-row: 1/2;
    grid-column: 1/-1;
    margin: 0;
    padding: 1rem;
    margin-left: auto;
    text-align: right;
    max-width: 60%;
  }

  @media ${QUERY.mediumSmall} {
    font-size: calc(1 * var(--baseline-size));
  }

  @media ${QUERY.smaller} {
    max-width: 70%;
  }
`

const Titolo = styled.h3`
  padding: 1rem 1rem 0.5rem;
  font-size: calc(1.2 * var(--baseline-size));
  font-weight: 600;
  border-radius: 20px;
  text-transform: uppercase;
`
const Testo = styled(Markdown)`
  padding: 1rem;
  font-size: calc(1 * var(--baseline-size));
  font-weight: 300;

  @media ${QUERY.mediumSmall} {
    font-size: calc(0.9 * var(--baseline-size));
  }
`

const ParaOne = styled.div`
  grid-row: 1/2;
  grid-column: 1/3;
  margin: 2rem 0;

  @media ${QUERY.medium} {
    grid-row: 2/3;
    grid-column: 1/-1;
  }
`
const ParaTwo = styled.div`
  grid-row: 3/4;
  grid-column: 1/3;
  margin: 2rem 0;

  @media ${QUERY.medium} {
    grid-row: 4/5;
    grid-column: 1/-1;
  }
`
const ParaThree = styled.div`
  grid-row: 5/6;
  grid-column: 1/3;
  margin: 2rem 0;
  @media ${QUERY.medium} {
    grid-column: 1/-1;
  }
`

const Bg = styled.div`
  display: none;
  @media ${QUERY.medium} {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 15%;
    background: ${COLORS.lightBlue};
    width: 100%;
  }
`

export default Taichichuan
