import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components/macro"

import { QUERY, COLORS } from "../../constants"

const IconPush = ({ label, href, target, icon }) => {
  return (
    <Wrapper href={href} target={target}>
      <FlexWrapper>
        {icon && <Icon src={icon.file.publicURL} alt="" />}
        <Label>{label}</Label>
      </FlexWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.a`
  position: absolute;
  bottom: -10px;
  right: -10px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: ${COLORS.white};
  display: inline-block;
  text-decoration: none;
  padding: 0.5rem;
  -webkit-box-shadow: inset 5px 5px 10px 0px #ffffff, 5px 5px 10px 0px #cccccc;
  -moz-box-shadow: inset 5px 5px 10px 0px #ffffff, 5px 5px 10px 0px #cccccc;
  -o-box-shadow: inset 5px 5px 10px 0px #ffffff, 5px 5px 10px 0px #cccccc;
  box-shadow: inset 5px 5px 10px 0px #ffffff, 5px 5px 10px 0px #cccccc;
  @media ${QUERY.verySmall} {
    width: 70px;
    height: 70px;
  }
`
const Icon = styled.img`
  width: 30px;
`
const FlexWrapper = styled.div`
  display: grid;
  place-items: center;
`

const Label = styled.span`
  width: 50px;
  color: ${COLORS.red};
  text-transform: uppercase;
  font-size: calc(0.7 * var(--baseline-size));
  text-align: center;
  @media ${QUERY.verySmall} {
    font-size: calc(0.5 * var(--baseline-size));
  }
`

IconPush.propTypes = {
  label: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
}

export default IconPush
