import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components/macro"

import { QUERY, COLORS } from "../../constants"

const Hero = () => {
  const data = useStaticQuery(graphql`
    query HeroQuery {
      strapiHero {
        titoloTop
        titoloBottom
        bannerCorsi
        citazione1
        citazione2
        citazione3
        definizione
        filoRosso {
          file {
            publicURL
          }
        }
        ideogrammaNero {
          file {
            publicURL
          }
        }
        yuanRosso {
          file {
            publicURL
          }
        }
        logo {
          file {
            publicURL
          }
        }
      }
    }
  `)

  const {
    titoloTop,
    titoloBottom,
    definizione,
    citazione1,
    citazione2,
    citazione3,
    bannerCorsi,
    logo,
    yuanRosso,
    ideogrammaNero,
    filoRosso,
  } = data.strapiHero

  const InlineLogo = () => (
    <LogoInCitazione src={yuanRosso.file.publicURL} alt="" />
  )
  const CitazioneWithLogo = () => (
    <Citazione>
      <span>{citazione1}</span>
      <InlineLogo />
      <span>{citazione2}</span> <InlineLogo />
      <span>{citazione3}</span>{" "}
    </Citazione>
  )

  return (
    <>
      <Wrapper>
        <Header>
          <Logo src={logo.file.publicURL} alt="" />
          <Title>
            <TitleTopWrapper>
              <RedLogo src={yuanRosso.file.publicURL} alt="" />
              <TitleTop>{titoloTop}</TitleTop>
            </TitleTopWrapper>
            <TitleBottom>{titoloBottom}</TitleBottom>
          </Title>
        </Header>
        <CitazioneWithLogo style={{ fontFamily: "var(--font-serif)" }} />
        <Definizione>
          <Ideogramma src={ideogrammaNero.file.publicURL} alt="" />
          <span>{definizione}</span>
        </Definizione>
      </Wrapper>
      <Banner>{bannerCorsi}</Banner>
      <Filo src={filoRosso.file.publicURL} alt="" />
    </>
  )
}

const Wrapper = styled.div`
  padding: 10rem;
  padding-bottom: 0;

  @media ${QUERY.larger} {
    padding: 4rem;
    padding-bottom: 0;
  }

  @media ${QUERY.small} {
    padding: 2rem;
    padding-bottom: 0;
  }

  @media ${QUERY.verySmall} {
    padding: 1rem;
    padding-bottom: 0;
  }
`
const Header = styled.header`
  display: flex;
  align-items: center;
  text-shadow: 2px 2px #fff;
`
const Logo = styled.img`
  width: 12rem;
  height: 12rem;

  @media ${QUERY.medium} {
    width: 8rem;
    height: 8rem;
  }

  @media ${QUERY.small} {
    width: 5rem;
    height: 5rem;
  }

  @media ${QUERY.verySmall} {
    width: 3.3rem;
    height: 3.3rem;
  }
`
const RedLogo = styled.img`
  height: calc(4.3 * var(--baseline-size));
  margin-right: 1.8rem;
  margin-top: 0.9rem;

  @media ${QUERY.medium} {
    height: calc(2.8 * var(--baseline-size));
    margin-right: 0.8rem;
    margin-top: 0.5rem;
  }
  @media ${QUERY.small} {
    margin-right: 0.4rem;
    margin-top: 0.25rem;
    height: calc(2 * var(--baseline-size));
  }
  @media ${QUERY.smaller} {
    height: calc(1.6 * var(--baseline-size));
  }
  @media ${QUERY.smallest} {
    height: calc(1.4 * var(--baseline-size));
  }
  @media ${QUERY.verySmall} {
    height: calc(1.2 * var(--baseline-size));
  }
`

const Title = styled.div`
  margin-top: 0.8rem;
  margin-left: auto;
  font-size: calc(4.8 * var(--baseline-size));
  font-weight: 100;
  text-transform: uppercase;

  @media ${QUERY.medium} {
    margin-top: 0.5rem;
    font-size: calc(3 * var(--baseline-size));
  }

  @media ${QUERY.small} {
    font-size: calc(2 * var(--baseline-size));
  }

  @media ${QUERY.smaller} {
    font-size: calc(1.8 * var(--baseline-size));
  }

  @media ${QUERY.smallest} {
    font-size: calc(1.6 * var(--baseline-size));
  }

  @media ${QUERY.verySmall} {
    font-size: calc(1.4 * var(--baseline-size));
  }
`
const TitleTopWrapper = styled.span`
  display: flex;
  justify-content: flex-end;
`
const TitleTop = styled.div``
const TitleBottom = styled.div`
  text-align: right;
`

const Filo = styled.img`
  position: absolute;
  top: 185px;
  right: 0;
  z-index: -2;
  @media ${QUERY.larger} {
    top: 180px;
  }

  @media ${QUERY.large} {
    top: 230px;
  }

  @media ${QUERY.hero} {
    top: 270px;
  }

  @media ${QUERY.medium} {
    top: 140px;
  }

  @media ${QUERY.hero1042} {
    top: 160px;
  }

  @media ${QUERY.mediumSmall} {
    top: 270px;
  }

  @media ${QUERY.small} {
    top: 240px;
  }

  @media ${QUERY.hero600} {
    top: 320px;
  }

  @media ${QUERY.smaller} {
    top: 380px;
  }
  @media ${QUERY.smallest} {
    top: 430px;
  }

  @media ${QUERY.customQuery} {
    top: 540px;
  }

  @media ${QUERY.verySmall} {
    top: 300px;
  }
`

const Definizione = styled.p`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  text-align: right;
  font-weight: 700;
  margin-top: 8rem;
  font-size: calc(0.9 * var(--baseline-size));
  color: ${COLORS.red};
  margin-left: auto;
  width: 40%;
  padding: 1rem 0.5rem;
  @media ${QUERY.medium} {
    width: 60%;
  }
  @media ${QUERY.small} {
    width: 70%;
    font-size: calc(0.8 * var(--baseline-size));
  }
  @media ${QUERY.verySmall} {
    margin-top: 6rem;
    font-size: calc(0.6 * var(--baseline-size));
  }
`
const Ideogramma = styled.img`
  width: 3rem;
  padding: 0.1rem;
`

const Banner = styled.div`
  padding: 1rem 2rem;
  display: grid;
  place-items: center;
  background: ${COLORS.red};
  color: ${COLORS.white};
  text-transform: uppercase;
  text-align: center;
  font-size: calc(1.5 * var(--baseline-size));
  @media ${QUERY.medium} {
    font-size: calc(1.2 * var(--baseline-size));
  }
  @media ${QUERY.small} {
    font-size: calc(1 * var(--baseline-size));
  }
`

const Citazione = styled.div`
  font-family: var(--font-serif);
  font-size: calc(2 * var(--baseline-size));
  width: 65%;
  margin-top: 6rem;
  text-transform: uppercase;
  @media ${QUERY.medium} {
    font-size: calc(1.5 * var(--baseline-size));
  }
  @media ${QUERY.small} {
    width: 80%;
  }
  @media ${QUERY.verySmall} {
    width: 80%;
    font-size: calc(1 * var(--baseline-size));
  }
`

const LogoInCitazione = styled.img`
  height: calc(1.7 * var(--baseline-size));
  margin: -0.05rem 0.4rem;
  padding-top: 0.2rem;
  text-shadow: none;

  @media ${QUERY.medium} {
    height: calc(1.4 * var(--baseline-size));
    margin-top: 0;
  }

  @media ${QUERY.verySmall} {
    height: calc(1.2 * var(--baseline-size));
  }
`

export default Hero
